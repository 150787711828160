import blackSeptemberTheme from '~/core/config/promo-theme/themes/black-september.theme'
import defaultTheme from '~/core/config/promo-theme/themes/default.theme'
import octoberTheme from '~/core/config/promo-theme/themes/october.theme'

import { PromoTheme } from '~/core/config/promo-theme/types'

// Please keep the order of themes per priority
// The first will have the highest priority
// and will be applied in case a feature flag is enabled.
// Default theme should not be included here.
export const orderedThemes: PromoTheme[] = [
  octoberTheme,
  blackSeptemberTheme,
]

export default defaultTheme
