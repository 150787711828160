import { PromoTheme } from '~/core/config/promo-theme/types'

const theme: PromoTheme = {
  featureFlag: null,
  options: {
    darkMode: false,
    pages: {
      home: {
        mainBanner: {
          images: [
            require('~/assets/images/main-banner/alt-person_1x.png'),
            require('~/assets/images/main-banner/alt-person_2x.png'),
          ],
          title: {
            text: 'home.newJumbotron.title',
          },
          counter: {
            oneCounterMode: true,
          },
          cta: {
            text: 'home.findYourPlan',
          },
        },
      },
      comparator: {
        featuresBanner: {
          images: [
            require('~/assets/images/main-banner/alt-person_1x.png'),
            require('~/assets/images/main-banner/alt-person_2x.png'),
          ],
        },
      },
      osp: {
        topBanner: {
          images: [
            require('~/assets/images/main-banner/alt-person_1x.png'),
            require('~/assets/images/main-banner/alt-person_2x.png'),
          ],
        },
      },
    },
  },
}

export default theme
