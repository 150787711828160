import { NuxtApp } from '@nuxt/types/app'
import defaultTheme, { orderedThemes } from './themes'
import { PromoThemeOptions } from '~/core/config/promo-theme/types'

export function getPromoTheme (ctx: NuxtApp): PromoThemeOptions {
  const {
    $featureFlags,
  } = ctx

  const enabledThemes = orderedThemes.filter(theme => theme.featureFlag && $featureFlags.isEnabled(theme.featureFlag))
  const theme = enabledThemes.length ? enabledThemes[0] : defaultTheme

  return theme.options
}
