
import { Component, namespace } from 'nuxt-property-decorator'
import SavingsCounter from '~/components/SavingsCounter/SavingsCounter.vue'
import PromoBadge from '~/components/badges/PromoBadge.vue'
import { CommonState, namespace as commonStore } from '~/store/modules/Common'
import { FeatureFlags } from '~/core/config/feature-flags'
import PromoThemeMixin from '~/mixins/promo-theme.mixin'
import ResponsiveImage from '~/components/ResponsiveImage/ResponsiveImage.vue'

const CommonStore = namespace(commonStore)

@Component({
  components: {
    ResponsiveImage,
    SavingsCounter,
    PromoBadge,
  },
})
export default class NewMainBanner extends PromoThemeMixin {
  featureBlackApril = this.$featureFlags.isEnabled(FeatureFlags.BLACK_APRIL_PROMO)
  featureBlackSeptember = this.$featureFlags.isEnabled(FeatureFlags.BLACK_SEPTEMBER_PROMO)

  @CommonStore.State('totalOrders')
  readonly totalOrders!: CommonState['totalOrders']

  @CommonStore.State('totalSavings')
  readonly totalSavings!: CommonState['totalSavings']

  onCTAClick () {
    const btnText = this.$t('home.newJumbotron.startSaving') as string

    this.$analytics.gtm.sendQuizCTAClickEvent(this.$i18n.locale, btnText)

    this.$router.push(this.localePath('quiz'))
  }
}
