
import { Component, Prop, Vue } from 'nuxt-property-decorator'
import ResponsiveImage from '~/components/ResponsiveImage/ResponsiveImage.vue'
import { PromoBadgeSize } from '~/core/config/promo-theme/types'

@Component({
  components: {
    ResponsiveImage,
  },
})
export default class PromoBadge extends Vue {
  @Prop({
    type: Array,
    required: true,
  })
  readonly images!: string[]

  @Prop({
    type: Boolean,
    default: false,
  })
  readonly shadow!: boolean

  @Prop({
    type: String,
    default: 'md',
  })
  readonly size!: PromoBadgeSize
}
