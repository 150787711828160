import { PromoTheme } from '~/core/config/promo-theme/types'
import { FeatureFlags } from '~/core/config/feature-flags'

const theme: PromoTheme = {
  featureFlag: FeatureFlags.OCTOBER_PROMO_THEME,
  options: {
    darkMode: false,
    pages: {
      home: {
        mainBanner: {
          images: [
            require('~/assets/images/promos/october/main-banner-hero.webp'),
            require('~/assets/images/promos/october/main-banner-hero@2x.webp'),
          ],
          title: {
            text: 'home.newJumbotron.title',
          },
          counter: {
            oneCounterMode: true,
          },
          cta: {
            text: 'home.newJumbotron.startSaving',
          },
        },
      },
      comparator: {
        featuresBanner: {
          images: [
            require('~/assets/images/promos/october/features-banner-image.webp'),
            require('~/assets/images/promos/october/features-banner-image@2x.webp'),
          ],
        },
      },
      osp: {
        topBanner: {
          images: [
            require('~/assets/images/promos/october/osp-banner-image.webp'),
            require('~/assets/images/promos/october/osp-banner-image@2x.webp'),
          ],
        },
      },
    },
  },
}

export default theme
